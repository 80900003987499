"use client";

import Container from "@/components/containers/container";
import { Alert } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type ErrorProps = {
  error: Error;
  reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  const { t } = useTranslation("common");
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Container className={"flex-1 min-h-36"}>
      <div className="py-24">
        <Alert
          message="Error"
          description={t("error-heading")}
          type="error"
          showIcon
        />
        {/* <Alert message={t("error-heading")} /> */}
      </div>
      {/* <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button> */}
    </Container>
  );
}
